<template>
    <v-dialog v-model="visible" persistent max-width="600px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5">{{ form.id ? 'Edição' : 'Cadastro' }} de Campanha</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text
                align="center"
                justify="center"
                :style="`max-height: ${$vuetify.breakpoint.height - 240}px; overflow-x: auto;`"
            >
                <fieldset 
                    v-if="showStatusBox" 
                    class="ma-2 v-fieldset"
                >
                    <div style="display: flex; align-items: center; justify-content: center;">
                        <div 
                            v-if="form.status === 'executando'" 
                            class="status-dot-success pulsating-shadow blinking"
                        />
                        <div 
                            v-if="form.status === 'pausado'" 
                            class="status-dot-warning"
                        />
                        <span style="font-size: 12px; font-weight: bold;" class="ml-2">
                            {{ form.status === 'executando' ? 'Executando' : 'Pausado ' }} {{ getEnvios }}
                        </span>
                    </div>
                    <v-progress-linear
                        :value="getProgress"
                        height="15"
                        rounded
                    >
                        <strong>{{ Math.ceil(getProgress) }}%</strong>
                    </v-progress-linear>
                    <v-btn 
                        v-if="form.status === 'pausado'" 
                        @click="play" 
                        :loading="loadingActions"
                        icon 
                        color="primary"
                    >
                        <v-icon>
                            mdi-play
                        </v-icon>
                    </v-btn>
                    <v-btn 
                        v-if="form.status === 'executando'" 
                        @click="pause" 
                        :loading="loadingActions"
                        icon    
                        color="primary"
                    >
                        <v-icon>
                            mdi-pause
                        </v-icon>
                    </v-btn>
                    <v-btn 
                        v-if="form.status === 'executando'" 
                        @click="stop" 
                        :loading="loadingActions"
                        icon 
                        color="error"
                    >
                        <v-icon>
                            mdi-stop-circle-outline
                        </v-icon>
                    </v-btn>
                </fieldset>
                <v-expansion-panels v-model="panels" multiple readonly focusable class="pa-2">
                    <v-expansion-panel>
                        <v-expansion-panel-header>Nome e Público</v-expansion-panel-header>
                        <v-expansion-panel-content class="pt-4">
                            <v-text-field
                                label="Nome da campanha"
                                v-model="form.nome"
                                outlined
                                dense
                                hide-details
                                class="mt-2"
                            />
                            <v-autocomplete
                                prepend-inner-icon="mdi-magnify"
                                v-model="form.publico_id"
                                :items="publicos"
                                dense
                                :item-text="e => `${e.id} - ${e.nome}`"
                                item-value="id"
                                clearable
                                label="Público"
                                outlined
                                class="mt-3"
                            >
                                <template #append-item>
                                    <div
                                        style="font-size: 14px; font-weight: bold; padding: 14px; cursor: pointer;"
                                        @click="newPublico"
                                    >
                                        <v-icon>mdi-plus</v-icon> Cadastrar Novo Público
                                    </div>
                                </template>
                                <template #append>
                                    <v-btn
                                        v-if="form.publico_id"
                                        @click="editarPublico"
                                        small
                                        icon
                                        class="mr-2"
                                    >
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn
                                        @click="newPublico"
                                        small
                                        outlined
                                    >
                                        <v-icon>mdi-plus</v-icon> Novo
                                    </v-btn>
                                </template>
                            </v-autocomplete>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>Programação</v-expansion-panel-header>
                        <v-expansion-panel-content class="pt-4">
                            <div>
                                <span class="caption">
                                    Selecione os dias da semana e informe o horário de inicio e fim de execução.
                                    A campanha será enviada automáticamente nos dias e período definido.
                                </span>
                            </div>
                            <div class="mt-4">
                                <span class="font-weight-bold">Dias da semana:</span>
                            </div>
                            <v-btn-toggle v-model="form.programacao.dias" multiple class="mt-4" :style="cssVars">
                                <v-btn v-for="(btn, i) in buttons" :value="btn.value" :key="i">
                                    <v-icon>mdi-alpha-{{ btn.char }}</v-icon>
                                </v-btn>
                            </v-btn-toggle>

                            <div class="mt-4">
                                <span class="font-weight-bold">Hora de inicio e fim:<br>(Horário de Brasilia)</span>
                            </div>
                            <div style="display: flex; justify-content: center;" class="mt-2">
                                <v-text-field
                                    v-model="form.programacao.start"
                                    type="time"
                                    style="max-width: 90px;"
                                    hide-details
                                    outlined
                                    dense
                                />
                                <v-text-field
                                    v-model="form.programacao.end"
                                    type="time"
                                    style="max-width: 90px;"
                                    hide-details
                                    outlined
                                    dense
                                />
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>Conteúdo</v-expansion-panel-header>
                        <v-expansion-panel-content class="pt-4">
                            <div style="border: solid 1px #999; border-radius: 5px;">
                                <b>Arquivo (ogg, png, mp4)</b>
                                <FileInput
                                    ref="file"
                                    v-model="form.arquivo"
                                    accept=".ogg, .png, .mp4"
                                    validateType="ogg, png, mp4"
                                />
                            </div>

                            <v-textarea
                                v-model="form.mensagem"
                                label="Mensagem"
                                outlined
                                class="mt-4"
                                rows="10"
                                hide-details
                            >
                                <template #append>
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-chat-plus</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-list>
                                            <v-card-title>Adicionar Variável</v-card-title>

                                            <v-list-item @click="addVariavel('nome')">
                                                <v-list-item-title>
                                                    <v-icon class="mr-2">mdi-account</v-icon>
                                                    <span>Nome do Cliente</span>
                                                </v-list-item-title>
                                            </v-list-item>

                                            <!-- <v-list-item @click="addVariavel('link')">
                                                <v-list-item-title>
                                                    <v-icon class="mr-2">mdi-link-variant</v-icon>
                                                    <span>Link do Cardápio</span>
                                                </v-list-item-title>
                                            </v-list-item> -->
                                        </v-list>
                                    </v-menu>
                                </template>
                            </v-textarea>

                            <div style="text-align: left;">
                                <v-menu offset-y left :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>
                                                mdi-help-circle-outline
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title>
                                            Dica de formatação
                                        </v-card-title>
                                        <v-card-text style="text-align: left;">
                                            <ul>
                                                <li>
                                                    Coloque um asterisco (*) antes e depois da palavra ou frase para deixá-la em *<b>negrito</b>*.
                                                </li>
                                                <li>
                                                    Coloque um underscore (_) antes e depois da palavra ou frase para deixá-la em _<i>itálico</i>_.
                                                </li>
                                                <li>
                                                    Coloque um til (~) antes e depois da palavra ou frase para ~<s>riscá-la</s>~.
                                                </li>
                                            </ul>
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
                <v-btn color="success" @click="save()" :loading="loading">
                    <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
        <CadastroPublico
            :visible="dialogPublicoVisible"
            :formData="formPublico"
            @onCadastro="onCadastro"
            @close="closePublico"
        />
    </v-dialog>
</template>

<script>
import FileInput from '@/components/FileInput';
import CadastroPublico from './Publico';

export default {
    name: 'Campanha',

    components: {
        FileInput,
        CadastroPublico,
    },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        formData: {
            type: Object,
            default: Object,
        },
    },

    data: () => ({
        loading: false,
        loadingActions: false,
        dialogPublicoVisible: false,
        formPublico: null,
        form: {
           id: null,
           programacao: {},
           mensagem: '',
        },
        interval: null,
        progress: {},
        canais: ['whatsapp'],
        publicos: [],
        toggle_multiple: [],
        panels: [0, 1, 2],
        buttons: [
            { value: 1, char: 's' },
            { value: 2, char: 't' },
            { value: 3, char: 'q' },
            { value: 4, char: 'q' },
            { value: 5, char: 's' },
            { value: 6, char: 's' },
            { value: 7, char: 'd' },
        ],
    }),

    computed: {
        cssVars() {
            return {
                '--bg-color': '#3874d0',
                '--color': '#fff',
            };
        },

        showStatusBox() {
            return ['executando', 'pausado'].includes(this.form.status);
        },

        getProgress() {
            const envios = this.progress.envios;
            const tamanhoPublico = this.progress.publico?.tamanho_publico || 0;
            
            if (tamanhoPublico === 0) {
                return 0;
            }
            
            const percentual = (envios / tamanhoPublico) * 100;
            return percentual;
        },

        getEnvios() {
            const envios = this.progress.envios;
            const tamanhoPublico = this.progress.publico?.tamanho_publico || 0;
            
            if (tamanhoPublico === 0) {
                return '';
            }
            
            const text = `(${envios} / ${tamanhoPublico})`;
            return text;
        },
    },

    watch: {
        visible(v) {
            v ? this.fillPublicos() : this.reset();
        },

        formData(value) {
            if (!value) {
                clearInterval(this.interval);
                return;
            }
            this.form = value;
            this.retrieveProgress();
            this.interval = setInterval(() => this.retrieveProgress(), 15000);
        },
    },

    methods: {
        validate() {
            const days = this.form.programacao?.dias || [];
            const start = this.form.programacao?.start;
            const end = this.form.programacao?.end;

            if (!this.form.nome) {
                this.notify('Informe o nome', 'warning');
                return;
            }

            if (days.length) {
                if (!this.form.publico_id) {
                    this.notify('Informe o publico', 'warning');
                    return;
                }

                if (!this.moment(start, 'HH:mm').isValid()) {
                    this.notify('Horário de inicio inválido', 'warning');
                    return;
                }

                if (!this.moment(end, 'HH:mm').isValid()) {
                    this.notify('Horário de fim inválido', 'warning');
                    return;
                }

                if (start > end) {
                    this.notify('Horário de inicio deve ser anterior ao horário de fim', 'warning');
                    return;
                }

                if (!this.form.mensagem && !this.form.arquivo) {
                    this.notify('Informe o conteúdo', 'warning');
                    return;
                }
            }

            return true;
        },

        save() {
            const hasErrors = !this.validate();
            if (hasErrors) {
                return;
            }

            const id = this.form.id;

            if (id) {
                this.loading = true;
                this.$http.put(`campanhas/${id}`, this.form).then(resp => {
                    if (resp.data.type === 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return
                    }

                    this.notify('Atualizado com sucesso!');
                    this.$emit('close');
                }).catch(() => {
                    this.notify('Não foi possivel concluir a operação', 'warning');
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('campanhas', this.form).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return
                }

                this.notify('Salvo com sucesso!');
                this.$emit('close');
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },

        reset() {
            this.form = {
                id: null,
                programacao: {},
            };
        },

        newPublico() {
            this.dialogPublicoVisible = true;
        },

        closePublico() {
            this.dialogPublicoVisible = false;
        },

        onCadastro() {
            this.closePublico();
            this.fillPublicos();
        },

        fillPublicos() {
            this.$http.get('publicos').then(resp => {
                this.publicos = resp.data.data;
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            });
        },

        addVariavel(variable) {
            this.form.mensagem += ' {{' + variable + '}}';
        },

        editarPublico() {
            const publico = this.publicos.find(e => e.id == this.form.publico_id);
            this.formPublico = publico;
            this.dialogPublicoVisible = true;
        },

        play() {
            this.loadingActions = true;
            this.$http.post(`campanhas/${this.form.id}/play`).then(() => {
                this.notify('Feito!');
                this.getById();
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            });
        },

        pause() {
            this.loadingActions = true;
            this.$http.post(`campanhas/${this.form.id}/pause`).then(() => {
                this.notify('Feito!');
                this.getById();
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            });
        },

        async stop() {
            const confirm = await this.$root.$confirm(
                'Confirmação',
                'Deseja realmente parar a campanha e cancelar os envios pendentes?',
                { color: 'red darken-1' }
            );

            if (!confirm) {
                return;
            }

            this.loadingActions = true;
            this.$http.post(`campanhas/${this.form.id}/stop`).then(() => {
                this.notify('Feito!');
                this.getById();
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            });
        },

        getById() {
            this.$http.get(`campanhas/${this.form.id}`).then(resp => {
                this.form = resp.data;
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loadingActions = false));
        },

        retrieveProgress() {
            if (this.form.status !== 'executando') {
                return;
            }
            this.$http.get(`campanhas/${this.form.id}/progress`).then(resp => {
                this.progress = resp.data;
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            });
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}

.v-card__text {
    padding: 0 !important;
}

.v-expansion-panel-header {
    font-weight: bold;
}

.v-btn--active {
    background-color: var(--bg-color) !important;
    color: var(--color) !important;
}

.v-fieldset {
    border-color: #999;
    border-radius: 5px;
    padding: 8px;
}

.v-legend {
    color: #707070;
}

.status-dot-success {
    width: 10px; 
    height: 10px; 
    border-radius: 5px; 
    background: #4caf50;
}

.status-dot-warning {
    width: 10px; 
    height: 10px; 
    border-radius: 5px; 
    background: #e31b1b;
}

.pulsating-shadow {
    animation: shadow-pulse 1.5s infinite;
}

@keyframes shadow-pulse {
    0% {
        box-shadow: 0 0 2px #4caf50;
    }
    50% {
        box-shadow: 0 0 10px #4caf50;
    }
    100% {
        box-shadow: 0 0 2px #4caf50;
    }
}

.blinking {
    animation: blink 1.5s infinite;
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.1;
    }
}
</style>
